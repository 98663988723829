import { Picture, Typography } from '@which/seatbelt'

import type { MemberBenefitPageLink } from '../../../../../../generated/frontend'
import { Link } from '../../../../../../shared/components/Link'
import styles from './BenefitLink.module.scss'

interface MemberBenefitsLinkProps {
  link: MemberBenefitPageLink
}

export const BenefitLink = ({ link: { image, text, href } }: MemberBenefitsLinkProps) => {
  return (
    <div className={styles.benefitLinkRow} data-testid="benefits-link-div">
      <Picture
        src={image}
        alt={text}
        aspectRatioMain="one-to-one"
        height="62"
        width="62"
        className={styles.benefitLinkRowIcon}
        imageClassName={styles.benefitLinkRowIconImage}
        aria-hidden
      />

      <Link href={href} data-which-id="benefits-panel-link" data-testid="benefits-link">
        <Typography tag="span" textStyle="sb-text-body-default-strong">
          {text}
        </Typography>
      </Link>
    </div>
  )
}
