import type { FunctionComponent } from 'react'
import { Heading, Typography } from '@which/seatbelt'

import type { MemberBenefitsInfoQuery } from '../../../../../../generated/frontend'
import { BenefitLink } from '../BenefitLink/BenefitLink'
import { OfferBox } from '../OfferBox/OfferBox'
import styles from './PersonalisedMemberBenefitsLoggedIn.module.scss'

type PersonalisedMemberBenefitsLoggedInProps = {
  links?: MemberBenefitsInfoQuery['memberBenefitsInfo']['links']
  offer?: MemberBenefitsInfoQuery['memberBenefitsInfo']['offer']
}

export const PersonalisedMemberBenefitsLoggedIn: FunctionComponent<
  PersonalisedMemberBenefitsLoggedInProps
> = ({ links, offer, ...rest }) => {
  return (
    <article aria-labelledby={'member-benefits-heading'} className={styles.panel} {...rest}>
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id="member-benefits-heading"
          tag="h2"
          textStyle="sb-text-heading-large"
          className={styles.panelTitle}
        >
          Get the most from your membership
        </Typography>
      </div>
      {links ? (
        <div className={styles.panelSection}>
          {links.map((link, i) => {
            if (link.__typename == 'MemberBenefitPageLink') {
              return <BenefitLink link={link} key={i} />
            }
            console.error('Unexpected link type for logged in member benefits')
            return null
          })}
        </div>
      ) : null}
      {offer ? (
        <div className={styles.panelSection}>
          <Heading headingTag="h3" headingType="medium" heading="Member exclusive offer:" />
          <OfferBox offer={offer} />
        </div>
      ) : null}
      <div className={styles.panelSection}>
        <Typography textStyle="sb-text-body-default-regular">
          See all the details of your membership in{' '}
          <a href="https://www.which.co.uk/account/">your account</a> or{' '}
          <a href="https://www.which.co.uk/my-appointments#upcoming-appointments">
            book an appointment
          </a>
        </Typography>
      </div>
    </article>
  )
}
