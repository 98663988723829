import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import { useState } from 'react'
import { SignupButton, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type {
  MemberBenefitLink,
  MemberBenefitWidgetLink,
} from '../../../../../../generated/frontend'
import BenefitOverlay from '../BenefitOverlay/BenefitOverlay'
import { WidgetLinkList } from '../WidgetLink/WidgetLinkList'
import styles from './PersonalisedMemberBenefitsLoggedOut.module.scss'

type PersonalisedMemberBenefitsLoggedOutProps = {
  widgetLinks: MemberBenefitLink[]
} & ComponentPropsWithoutRef<'article'>

export const PersonalisedMemberBenefitsLoggedOut: FunctionComponent<
  PersonalisedMemberBenefitsLoggedOutProps
> = ({ widgetLinks, ...rest }: PersonalisedMemberBenefitsLoggedOutProps) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState<MemberBenefitLink | null>(null)

  const onWidgetClicked = (widget: MemberBenefitWidgetLink) => {
    setShowOverlay(true)
    setSelectedWidget(widget)
  }

  const onBackButtonPressed = () => {
    setShowOverlay(false)
  }

  return (
    <article className={classnames(styles.panel)} {...rest}>
      <div className={styles.headingContainer}>
        <Typography textStyle="sb-text-heading-overline" className={styles.panelOverline}>
          Subscribe to Which?
        </Typography>
        <Typography tag="h2" textStyle="sb-text-heading-large" className={styles.panelTitle}>
          Member Benefits
        </Typography>
      </div>
      <div className={styles.content}>
        <BenefitOverlay
          onBackButtonPressed={onBackButtonPressed}
          widgetLink={
            selectedWidget?.__typename == 'MemberBenefitWidgetLink' ? selectedWidget : null
          }
          hidden={!showOverlay}
        />
        <WidgetLinkList
          onWidgetClicked={onWidgetClicked}
          hidden={showOverlay}
          widgetLinks={widgetLinks}
        />
        <SignupButton
          caption={'First month is £5, then £10.99 per month, cancel any time'}
          buttonLink={{
            href: 'https://join.which.co.uk',
            text: 'Subscribe to Which?',
          }}
          data-which-id="benefits-panel-button"
        />
      </div>
    </article>
  )
}
