import { type FunctionComponent } from 'react'

import { useMemberBenefitsInfoQuery } from '../../../../generated/frontend'
import { PersonalisedMemberBenefitsLoggedIn } from './components/LoggedIn/PersonalisedMemberBenefitsLoggedIn'
import { PersonalisedMemberBenefitsLoggedOut } from './components/LoggedOut/PersonalisedMemberBenefitsLoggedOut'

export const PersonalisedMemberBenefits: FunctionComponent = ({ ...rest }) => {
  const { data: { memberBenefitsInfo } = {}, loading } = useMemberBenefitsInfoQuery()

  if (loading) {
    return null
  }

  const isLoggedIn = memberBenefitsInfo?.isLoggedIn

  if (isLoggedIn) {
    return (
      <PersonalisedMemberBenefitsLoggedIn
        links={memberBenefitsInfo?.links}
        offer={memberBenefitsInfo?.offer}
        data-testid="logged-in-benefits"
        {...rest}
      />
    )
  }

  return memberBenefitsInfo ? (
    <PersonalisedMemberBenefitsLoggedOut
      widgetLinks={memberBenefitsInfo?.links}
      data-testid="logged-out-benefits"
      {...rest}
    />
  ) : null
}
